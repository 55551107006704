import {useState, useEffect} from 'react';

const useTitle = (itemsButtons, value, defaultTitle, defaultIcon) => {

  const [title, setTitle] = useState(defaultTitle);
  const [iconTitle, setIconTitle] = useState(defaultIcon);

  useEffect(() => {
    if (value && value !== ''){

      const p = itemsButtons.filter((btn) => {return btn.path === value} );
      if (p.length){
        setTitle(p[0].label);
        setIconTitle(p[0].icon);
      }
      else{
        setTitle(defaultTitle);
        setIconTitle(defaultIcon);
      }
    }
    else{
      setTitle(defaultTitle);
      setIconTitle(defaultIcon);
    }

  }, [itemsButtons, value])

  return {
      title
      ,iconTitle
  }
}

export default useTitle;
