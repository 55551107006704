import React from "react";
import LinkToolbar from "../LinkToolbar";
import Main from "../Main";

const LinkToolbarPanel = (props) => {
  const {children, ...next} = props;
  return (
    <React.Fragment>
      <LinkToolbar {...next} >
        {children}
      </LinkToolbar>
      <Main {...next} />
    </React.Fragment>
  );
};

export default LinkToolbarPanel;
