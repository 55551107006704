import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles(() => ({
  LinkToolRoot: {
    flexGrow: 1
    ,position: 'static'
    ,margin:'0 0 0 0'
    ,minWidth:'100%'
    ,"& .MuiPaper-root": {
        marginBottom: "10px !important"
      }
  },
  LinkToolGrow: {
    flexGrow: 1,
  },
  LinkToolAppBarTitle: {
    paddingRight: 5
    ,paddingLeft: 20
    ,fontSize: '20px'
    ,"@media (max-width: 600px)" : {
        fontSize: '16px'
    }
    ,"@media (max-width: 370px)" : {
        fontSize: '12px'
    }
  }
  ,LinkToolAppBar : {
    margin: '0 0 0 0'
  }
  ,LinkToolA:{
    textDecoration: 'none'
    ,color: 'inherit'
  }
}));

const useStyleToolbar = props => {
    const classes = useStyles(props);

    return classes;
}

export default useStyleToolbar;
