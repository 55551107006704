import React from 'react';
import RouteSwitchPanel from '@sistemas.mundo/route-switch-panel';
import { Paper } from '@mui/material';
import ContainerPanel from '@sistemas.mundo/container-panel';

const MainPaper = (props) => {

    const {
        itemsPanels
        ,itemsButtons
        ,...next
    } = props;

    const getMenuItems = () => {
      const items = [...itemsButtons];

      return items.map((item) => {
        let {overflowY, ...next} = item || {};
        next.overflowY = overflowY ?? 'auto';
        return next;
      });

    }

    const style = {height: `calc(100vh - ${props.minusHeight})`, ...props.sx}

    return (
        <Paper
            style={style}
            elevation={2}
        >
          <ContainerPanel
            alignItems='stretch'
            justifyContent='flex-start'
            style={{position: 'relative'}}
            minusHeight={props.minusHeight}
            overflowY={props.overflowY}
            overflowX={props.overflowX}
          >
            <RouteSwitchPanel
                {...next}
                itemsPanels={getMenuItems()}
            />
          </ContainerPanel>
        </Paper>
    );
};

MainPaper.defaultProps = {
    itemsPanels: []
    ,defaultPath: null
    ,minusHeight: '0px'
    ,overflowY: 'hidden'
    ,overflowX: 'hidden'
}

export default MainPaper;
