import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import TooltipIconButton from '@sistemas.mundo/btn-icon-tooltip';
import MenuData from '@sistemas.mundo/menu-data';
import { Link, BrowserRouter as Router } from "react-router-dom";
import { Divider } from '@mui/material';
import useStyleToolbar from '../../hooks/useStyleToolbar';
import useTitle from '../../hooks/useTitle';
import usePathValue from '../../hooks/usePathValue';

const LinkToolBar = props => {

  const classes = useStyleToolbar();

  const { match, history, location, staticContext, children, ...rest} = props;

  const [size, setSize] = React.useState(window.innerWidth);
  const [value, basename] = usePathValue(props, props.itemsButtons);
  const {title, iconTitle} = useTitle(props.itemsButtons, value, props.defaultTitle, props.defaultIcon);

  React.useEffect(() => {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
  }, [size]);


  const updateWidth = () => {
      setSize(window.innerWidth)
  }

  const onClick = (e, btn) => {
    if (btn.path){
      return true;
    }
    else{
        e.preventDefault()
        e.stopPropagation()
        return false;
    }
  }

  const onIconClick = (btn) => {
    if (typeof(btn.onClick) === 'function'){
      if (btn.onClick(btn)){
        return true;
      }
    }
  }

  const getTooltip = (title, tooltip) => tooltip ? <React.Fragment><div><b>{title}</b></div><div>{tooltip}</div></React.Fragment> : title

  const getColorIcon = (btn) => {

    if (btn.disabled === true){
      return '#c4c4c4';
    }
    else if (value === btn.path && value){
      return props.colorSelected
    }
    else{
      return 'inherit'
    }

  }

  const getIcon = (btn, indice) => {

    return (
        <TooltipIconButton
            tooltip={getTooltip(btn.label, btn.tooltip)}
            key={'tib' + btn.label + indice}
            options={btn.options}
            disabled={btn.disabled}
            hidden={btn.hidden}
            onClick={() => onIconClick(btn)}
            options={{
              optionsButton : {
                style: {
                  color: getColorIcon(btn)
                }
              }
            }}
            icon={btn.icon}
        />
    )
  }

  const getLink = (btn, indice, rest) => {

    if (btn.disabled === true || btn.hidden === true){
      return getIcon(btn, indice, rest);
    }
    else if (!btn.path){
      return getIcon(btn, indice, rest);
    }
    else {
      return (
        <Link
            key={`lnk-tool-${btn.path}`}
            exact={false}
            to={`${btn.path}`}
            className={classes.LinkToolA}
            onClick={(e) => onClick(e, btn)}
            style={{color: value === btn.path ? props.colorSelected : 'inherit'}}
        >
            {getIcon(btn, indice, rest) }
        </Link>
      )
    }

  }

  const getChildren = () => {

    if (props.children) {
      return (
        <React.Fragment>
          <Divider orientation="vertical" flexItem style={{margin:3}}/>
          {props.children}
        </React.Fragment>
      )
    }
    return '';
  }

  const getItemsComponent = () => {

    return (
      <React.Fragment>
        <div
          style={{
            display: size >= 600 ? 'flex' : 'none'
            ,flexDirection: 'row'
            ,flexWrap: 'nowrap'
            ,alignItems: 'center'
          }}
        >
          {getChildren()}
          <Divider orientation="vertical" flexItem style={{margin:3}}/>
          {props.itemsButtons.map((btn, indice) => {
            return (
              getLink(btn, indice, rest)
            )
          })}
        </div>
        <div
          style={{
            display: size < 600 ? 'block' : 'none'
          }}
        >
          <React.Fragment>
            <MenuData itemsMenu={props.itemsButtons}>
              {props.children}
            </MenuData>
          </React.Fragment>
        </div>
      </React.Fragment>
    )

  }

  return (
    <Router basename={props.basename}>
      <div className={classes.LinkToolRoot}>
          <AppBar position="static" color='transparent' className={classes.LinkToolAppBarTitle}>
              <Toolbar variant="dense"  className={classes.LinkToolAppBar}>
                  {iconTitle}
                  <Typography variant="h6" color={props.titleColor} className={classes.LinkToolAppBarTitle} noWrap={true}>
                      {title}
                  </Typography>
                  <div className={classes.LinkToolGrow} />
                  {getItemsComponent()}
              </Toolbar>
          </AppBar>
      </div>
    </Router>
  );
}

LinkToolBar.defaultProps = {
    defaultTitle: 'Menú'
    ,defaultIcon: <DoubleArrowIcon/>
    ,basename: '/'
    ,defaultPath: null
    ,onIconClick: (newValue) => true
    ,itemsButtons: []
    ,titleColor: 'primary'
    ,colorSelected:"#1976d2"
}

export default LinkToolBar;
